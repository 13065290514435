import React from 'react';
import './Manual.css';
import { FaLaptopCode, FaQuestionCircle, FaExclamationTriangle } from "react-icons/fa";
import { MdDateRange, MdRepeatOn  } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { AiFillQuestionCircle } from "react-icons/ai";

export default function Manual() {
    return (
        <div className='manual'>
            <div className='suport'>
                <a className='btn-suport' href="https://api.whatsapp.com/send/?phone=22943753950&amp;text=Vim+do+site+do+CodeFind+e+preciso+tirar+uma+d%C3%BAvida&amp;type=phone_number&amp;app_absent=0"
                    target="__blank"><AiFillQuestionCircle />Suporte</a>
                <a  className='btn-share' href="https://api.whatsapp.com/send?text=Olha+essa+ferramenta+que+tem+nesse+site+www.codefind.app"
                    target="__blank" ><BsWhatsapp />Compartilhar</a>
            </div>

            <div className="comments">
                <h2>Manual</h2>
                <p><FaLaptopCode />Rastreio de boa qualidade pode minerar de 1000-1500 ativos a cada 10000 gerados.</p>
                <p><MdDateRange />Utilize uma matriz de rastreamento real de uma data específica para gerar outros da mesma data.</p>
                <p><FaQuestionCircle />Rastreios que estão com <strong>(Awaiting Seller Dispatch)</strong> no Canião é porque serão despachados em 24 horas.</p>
                <p><MdRepeatOn />A cada busca, espere 10 segundos antes para realizar uma nova. E ao buscar 3 vezes, recarregue a página para evitar sobrecarga.</p>
                <p><FaExclamationTriangle />Uso apenas para PC. Ative a extensão <strong>CORS Unblock</strong> para usar a ferramenta e desative ao acabar!</p>
            </div>
            
            <a className='global-caniao' href="https://chromewebstore.google.com/detail/lfhmikememgdcahcdlaciloancbhjino" target="__blank">Extensão CORS Unblock</a>
            <a className='global-caniao' href="https://global.cainiao.com" target="__blank"><IoLocation />GLOBAL CANIÃO</a>
        </div>
    );
}