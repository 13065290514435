import React, { useState, useEffect } from 'react';
import { InputWithLimit } from './InputWithLimit';
import InfoTable from './InfoTable';
import Table from './Table';
import Logs from './Logs';
import { fetchAPI, endpointFullData, endpointCheck } from '../helpers/fetchAPI';
import Swal from 'sweetalert2';
import logo from "../img/codefindLogo.png";
// import { GrDocumentTime } from "react-icons/gr";
import './Main.css';
import './Modal.css';

export default function Main({ fn_verifyUser, fn_referCode }) {
  const [source, setSource] = useState('');
  const [trackingCode, setTrackingCode] = useState('');
  const [destiny, setDestiny] = useState('BR');
  const [amount, setAmount] = useState(100);

  const [codes, setCodes] = useState(null);
  const [allValidCodes, setAllValidCodes] = useState([]);
  const [validCodes, setValidCodes] = useState([]);
  const [codesFull, setcodesFull] = useState([]);

  const [countdown, setCountdown] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [Checking, setChecking] = useState(false);
  //const [referCode, setReferCode] = useState('');
  // const [modalOpen, setModalOpen] = useState(true);

  const getCodes = async () => {
    setSearch(true);
    let verifyUser = await fn_verifyUser();
    if (!verifyUser) {
      setSearch(false);
      Swal.fire({
        title: "Usuário não autorizado!",
        icon: "warning",
        showConfirmButton: false,
        timer: 3000
      });
      return;
    };
    // await fn_referCode(setReferCode);
    setLoading(true);
    const codeList = [];
    const limitNumber = parseInt(amount, 10);
    if (!isNaN(limitNumber)) {
      for (let i = 0; i < limitNumber; i += 1) {
        let newTrackingCode = Number(trackingCode) + i;
        let formatedTrackingCode = String(newTrackingCode).padStart(9, '0');
        codeList.push(
          `${source.toUpperCase()}${String(
            formatedTrackingCode
          )}${destiny.toUpperCase()}`
        );
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCodes(codeList);
    }
  };

  const isFilled = () => {
    const minCodeLength = 9;
    return !(
      (source.length === 2 || source.length === 4) &&
      trackingCode.length === minCodeLength &&
      destiny.length === 2 &&
      amount > 0 &&
      !isSearch
    );
  };

  const delaySetChecking = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(setChecking(false));
      }, 5000);
    });
  };

  useEffect(() => {
    let timer;
    if (isSearch) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setCountdown(0);
    }

    return () => clearInterval(timer);
  }, [isSearch]);


  useEffect(() => {
    const fetchData = async () => {
      const listValidCodes = [];
      const listCodesDayOne = [];
      if (codes) {
        setLoading(true);

        const codeGroups = [];
        const batchSize = 100;
        for (let i = 0; i < codes.length; i += batchSize) {
          codeGroups.push(codes.slice(i, i + batchSize));
        }

        await Promise.all(
          codeGroups.map(async (codeGroup) => {
            let URL = endpointCheck;

            codeGroup.forEach((code, index) => {
              if (index === 0) {
                return (URL += `${code}`);
              }
              URL += `&mailNos=${code}`;
            });

            const responses = await fetchAPI(URL);
            // div logs
            // const logs = document.querySelector("#logs");

            // Processa as respostas
            responses.module?.forEach(({ mailNo, source }) => {
              if (source && (source === 'AE')) {
                /* console.log(mailNo, source);
                logs.innerHTML += `<p>${mailNo} Encontrado</p>`; */
                listValidCodes.push(mailNo);
              } /* else {
                logs.innerHTML += `<p>${mailNo} Não Encontrado</p>`;
              } */

              // Error
              if (responses.data?.ret) {
                if (responses.data.ret[0] === 'FAIL_SYS_USER_VALIDATE') {
                  setLoading(false);
                  //setError(true);
                  return;
                }
              }
            });
          })
        );

        const sortedCodes = [...listValidCodes]
          .sort((a, b) => b.localeCompare(a))
          .slice(0, 100);

        setAllValidCodes(listValidCodes);
        setValidCodes(sortedCodes);

        // -----------------------------------
        let url = endpointFullData;
        sortedCodes.forEach((code, index) => {
          if (index === 0) {
            return (url += `${code}`);
          }
          url += `&mailNos=${code}`;
        });

        new Promise((resolve) => {
          setTimeout(() => {
            resolve(setChecking(true));
          }, 5000)
        });

        let codeValidFull = [];
        const res = await fetchAPI(url);
        res.module?.forEach(({ mailNo, daysNumber = 'Ainda em separação', status, detailList = "Sem detalhes" }, index) => {
          let details = detailList[detailList.length - 1]?.timeStr;
          let newCode = { mailNo, status, destination: "", daysNumber, details : details ? details : "Sem detalhes"};
          codeValidFull.push(newCode);

          if (daysNumber === '1	day(s)' & status === 'DELIVERING') {
            listCodesDayOne.push(mailNo);
          }

        });

        /*         let newCode = {
                  code: sortedCodes[0], data: new Date().toLocaleDateString()
                };
                let retrievedList = localStorage.getItem('historyCodes');
                let historyCodes = retrievedList ? JSON.parse(retrievedList) : [];
                historyCodes.push(newCode);
                localStorage.setItem('historyCodes', JSON.stringify(historyCodes)); */

        setcodesFull(codeValidFull);
        await delaySetChecking();
        setLoading(false);
      }
    };

    fetchData();
  }, [codes]);

  useEffect(() => {
    if (isLoading) {
      Swal.fire({
        title: "Buscando... Aguarde",
        icon: "info",
        showConfirmButton: false,
        timer: 3000
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (Checking) {
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Buscando informações completas dos códigos mais recentes",
        showConfirmButton: false,
        timer: 3000
      });
      setSearch(false);
    }
  }, [Checking]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.shiftKey) {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event) => {
      if (event.ctrlKey || event.shiftKey) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener('contextmenu', handleContextMenu);


    const ctrlShiftKey = (e, keyCode) => {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    };


    const handleKeyDown = (e) => {
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const clearConsole = setInterval(() => {
      console.clear();
    }, 1000);

    return () => clearInterval(clearConsole);
  }, []);

  /*         useEffect(() => {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/disable-devtool';
            script.setAttribute('disable-devtool-auto', '');
            document.body.appendChild(script);
        
            return () => {
              document.body.removeChild(script);
            };
          }, []); */

  return (
    <div className='container__main'>
      <main>

        <section className="container__form">
          {/*           <h1 className='title'>
          CODE TRACKER</h1> */}
          <img className='title' src={logo} alt='Code Tracker Logo' />

          <form>
            <div className="input-row">
              <label htmlFor="source">
                <InputWithLimit
                  type={'text'}
                  placeholder={'ex: NM'}
                  value={source.toUpperCase()}
                  setValue={setSource}
                  limit={4}
                  id="source"
                />
              </label>
            </div>

            <div className="input-row">
              <label htmlFor="trackingCode">
                <InputWithLimit
                  type={'number'}
                  placeholder={'ex: 038255611'}
                  value={trackingCode}
                  setValue={setTrackingCode}
                  limit={9}
                  id="trackingCode"
                />
              </label>
            </div>

            <div className="input-row">
              <label htmlFor="destiny">
                <InputWithLimit
                  type={'text'}
                  placeholder={'ex: BR'}
                  value={destiny.toUpperCase()}
                  setValue={setDestiny}
                  limit={2}
                  id="destiny"
                />
              </label>
            </div>

            <div className="input-row">
              <label htmlFor="amount">
                <InputWithLimit
                  type={'select'}
                  placeholder={'ex: 50000'}
                  value={amount}
                  setValue={setAmount}
                  limit={6}
                  id="amount"
                />
              </label>
            </div>
            <button
              className="btn-get-codes"
              disabled={isFilled()}
              type="button"
              onClick={getCodes}
            >
              {isSearch ? 'Buscando...' : 'Buscar'}
              {isSearch && <span> {countdown}</span>}
            </button>
          </form>



          <div className="divisor">
            {/* <button
              type="button"
              className='modal-btn-close'
              onClick={() => setModalOpen(!modalOpen)}>
              <GrDocumentTime />
            </button> */}
          </div>
          {/*  {
            modalOpen && (
              <div className="modal" style={{ top: modalOpen ? 0 : '' }}>

                <div className="modal-content">
                  <h2>Referência</h2>
                  <p>
                    {referCode[0]}
                    <button
                      type="button"
                      className='modal-btn-use-code'
                      onClick={() => {
                        setSource(referCode[0].slice(0, 2));
                        setTrackingCode(referCode[0].slice(2, 11));
                        setDestiny(referCode[0].slice(11, 13));
                      }}
                    >
                      Usar
                    </button>
                  </p>

                  <div className="modal-codes">
                    <h2>Histórico</h2>
                    <table>
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(localStorage.getItem('historyCodes')).map((code, index) => (
                          <tr key={index}>
                            <td>{code.code}</td>
                            <td>{code.data}</td>
                              <button
                                type="button"
                                className='modal-btn-use-code'
                                onClick={() => {
                                  setSource(code.code.slice(0, 2));
                                  setTrackingCode(code.code.slice(2, 11));
                                  setDestiny(code.code.slice(11, 13));
                                }}
                              >
                                Usar
                              </button>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>

                  <button className='modal-btn-close' type="button" onClick={() => setModalOpen(!modalOpen)}>
                    <GrDocumentTime />
                  </button>
                </div>
              </div>
            )
          } */}

        </section>

        <section className="container__fulltable">
          <Table codes={codesFull} />
        </section>

      </main>

      <div className="container__info">
        <Logs
          founds={validCodes.length ? codes.length - (codes.length - allValidCodes.length) : 0}
          quantity={allValidCodes.length ? codes.length : 0}
          notFounds={allValidCodes.length ? codes.length - allValidCodes.length : 0}
        />

        <InfoTable
          title={`Mais Recentes: `}
          length={validCodes.length}
          codes={validCodes}
        />

        <InfoTable
          title={`Encontrados: `}
          length={allValidCodes.length}
          codes={allValidCodes}
        />

        {/*         <InfoTable
          title={`Códigos de 1 Dia`}
          length={codesDayOne.length}
          codes={codesDayOne}
          className="dayOne-codes-table"
        /> */}
      </div>

    </div>
  );
}