import React from 'react';
import './Logs.css';
import { BsInfoCircleFill } from "react-icons/bs";

export default function Logs({ founds, quantity, notFounds }) {
    return (
        <section className='container__logs'>
            <h1><BsInfoCircleFill />Dados da Pesquisa</h1>
            <div className='info'>
                <div className='info-1'>
                    <p>Verificados<br /> {quantity === 0 ? "Faça uma nova busca" : quantity}</p>
                </div>
                <div className='info-2'>
                    <p> Encontrados<br /> {founds === 0 ? "Faça uma nova busca" : founds}</p>
                </div>
                <div className='info-3'>
                    <p>Não encontrados<br /> {notFounds === 0 ? "Faça uma nova busca" : notFounds}</p>
                </div>
            </div>
            {/*             <div id="logs">

            </div> */}
        </section>
    );
}