import { GoogleLogout } from "react-google-login";
import "./Login.css";
import { FcGoogle } from "react-icons/fc";

const clientId = "65995926863-m4lql2v93gpiej6mqdsg65p1a20vv494.apps.googleusercontent.com";

export default function Logout({ logout }) {
    const onSuccess = () => {
        console.log("Log out successful");
        logout();
    };

    return (
        <div id="signOutButton">
            <GoogleLogout
                clientId={clientId}
                buttonText={"Logout do Google"}
                onLogoutSuccess={onSuccess}
                className="login-btn"
                render={(renderProps) => (
                    <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "70%",
                            height: "40px",
                            background: "rgb(255, 255, 255)",
                            color: "gray",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                        }}
                    >
                        <FcGoogle 
                            style={{
                                marginLeft: "5px",
                                fontSize: "30px",
                            }}
                        />
                        Sair do Google
                    </button>
                )}
            />
        </div>
    );
}