import React, { useState, useEffect } from "react";
import { fetchAPI, endpointCity } from "../helpers/fetchAPI";
import "./Table.css";
import Swal from 'sweetalert2';
import { FaCopy, FaDownload } from "react-icons/fa";

export default function Table({ codes }) {
    const [codeFull, setCodeFull] = useState([]);
    const [availableCities, setAvailableCities] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedDays, setSelectedDays] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (isCopied) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Códigos copiados para a área de transferência",
                timer: 1500
            });
        }
    }, [isCopied]);

    useEffect(() => {
        const fetchDestinations = async () => {
            const promises = codes.map(async ({ mailNo, destination, ...rest }) => {
                destination = await destinationCity(mailNo);
                return { mailNo, destination, ...rest };
            });

            const updatedCodes = await Promise.all(promises);

            updatedCodes.sort((a, b) => {
                const daysNumberA = parseInt(a.detail);
                const daysNumberB = parseInt(b.detail);
                return daysNumberA - daysNumberB;
            });

            setCodeFull(updatedCodes);

            const uniqueCities = Array.from(new Set(updatedCodes.map(code => code.destination)));
            uniqueCities.sort();

            const uniqueDays = Array.from(new Set(updatedCodes.map(code => code.daysNumber)));
            uniqueDays.sort();

            setAvailableDays(uniqueDays);
            setSelectedDays("");
            setAvailableCities(uniqueCities);
            setSelectedCity("");
        };

        fetchDestinations();
    }, [codes]);

    const filteredCodes = codeFull.filter(({ destination, daysNumber, detail }) => {
        if (selectedCity !== '' && destination !== selectedCity) {
            return false;
        }

        if (selectedDays !== '' && daysNumber !== selectedDays) {
            return false;
        }

        return true;
    });


    async function destinationCity(mailNo) {
        try {
            const URL = endpointCity(mailNo);
            const res = await fetchAPI(URL);
            return res.module.replace(/\+/g, ' ');
        } catch (error) {
            console.error("Error fetching destination city:", error);
            return "Unknown";
        }
    };

    const handleDownloadCSV = () => {
        const codesToCopy = filteredCodes
            .map(({ mailNo, destination, details }) =>
                `"${mailNo}","${destination}","${details}"`
            )
            .join("\r\n");

        const csvHeader = `"Código","Destino","Data de Compra"\r\n`;
        const csvContent = csvHeader + codesToCopy;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "codigos.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const handleDaysChange = (event) => {
        setSelectedDays(event.target.value);
    };

    const handleCopyCodes = async () => {
        const codesToCopy = filteredCodes.map(({ mailNo, destination, }) => mailNo).join("\n");
        navigator.clipboard.writeText(codesToCopy);
        setIsCopied(true);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        setIsCopied(false);
    };

    return (
        <div className="container__table">
            <div className="filter">
                <select id="cityFilter" value={selectedCity} onChange={handleCityChange}>
                    <option value="">Todas Destinos</option>
                    {availableCities.map((city, index) => (
                        <option key={index} value={city}>{city}</option>
                    ))}
                </select>

                <select id="daysFilter" value={selectedDays} onChange={handleDaysChange}>
                    <option value="">Todos Dias</option>
                    {availableDays.map((days, index) => (
                        <option key={index} value={days}>{days}</option>
                    ))}
                </select>

                <button className='btn-copy' onClick={handleDownloadCSV}>
                    <FaDownload title="Baixar Arquivo em CVG"/>
                </button>

                <button className='btn-copy' onClick={handleCopyCodes}>
                    <FaCopy/>
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Status</th>
                        <th>Destino</th>
                        <th>Dias</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCodes.map(({ mailNo, status, daysNumber, destination, details }) => (
                        <tr key={mailNo}>
                            <td>{mailNo}</td>
                            <td>{status}</td>
                            <td>{destination}</td>
                            <td>{daysNumber}</td>
                            <td>{details}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
