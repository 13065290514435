export const endpointFullData = 'https://global.cainiao.com/global/detail.json?mailNos=';
export const endpointCheck = 'https://global.cainiao.com/global/check.json?mailNos=';
export const endpointCity = (mailNo) => `https://global.cainiao.com/global/getCity.json?mailNo=${mailNo}&lang=en&language=en`;
// const url1 = 'https://corsproxy.io/?';/*  + encodeURIComponent('https://api.domain.com/...'); */

export const fetchAPI = async (URL) => {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
};

/* export const fetchAPI = async (URL) => {
    const url = {
        url: URL
    };

    if (URL.includes('detail')) {
        const response = await fetch(url1+encodeURIComponent(URL));
        const data = await response.json();
        return data;
    }

    console.log(url);

    const response = await fetch('https://mcr1lngmf3.execute-api.sa-east-1.amazonaws.com/Teste', {
        method: 'POST',
        body: JSON.stringify(url)
    });

    const responseData = await response.json();
    return responseData;
}; */

/* import https from 'https';

export const handler = async (event) => {
    try {
        const { url } = JSON.parse(event.body); // Assume que a URL é passada no corpo da requisição

        if (!url) {
            return {
                statusCode: 400,
                body: JSON.stringify({ error: 'URL missing' })
            };
        }


        const responseData = await fetchData(url); // Faz a solicitação para a URL fornecida


        return {
            statusCode: 200,
            body: JSON.stringify(responseData)
        };
    } catch (error) {
        console.error('Erro:', error);

        return {
            statusCode: 500,
            body: JSON.stringify({ error: error.message })
        };
    }
}; */

//const headers = { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
// 'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
// 'Accept-Language': 'en-US,en;q=0.9', }; 


/* const fetchData = async (url) => {
    return new Promise((resolve, reject) => {
        https.get(url, { headers }, (res) => {
            let data = '';

            res.on('data', (chunk) => {
                data += chunk;
            });

            res.on('end', () => {
                // Verifica se os dados contêm um JSON válido
                try {
                    const responseData = JSON.parse(data);
                    resolve(responseData);
                } catch (error) {
                    // Se não for um JSON válido, retorna um erro
                    reject(new Error('Os dados recebidos não são um JSON válido.'));
                }
            });
        }).on('error', (error) => {
            reject(error);
        });
    });
}; */


