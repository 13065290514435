import { GoogleLogin } from "react-google-login";
import "./Login.css";
import { FcGoogle } from "react-icons/fc";

const clientId = "65995926863-m4lql2v93gpiej6mqdsg65p1a20vv494.apps.googleusercontent.com";

export default function Login() {
    const onSuccess = (res) => {
        console.log("[Login Success] currentUser:", res.profileObj);
    };

    const onFailure = (res) => {
        console.log("[Login Failed] res:", res);
    };

    return (
        <div>
            <GoogleLogin
                clientId={clientId}
                buttonText="Faça Login com o Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                isSignedIn={true}
                className="login-btn"
                render={(renderProps) => (
                    <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "70%",
                            height: "40px",
                            background: "rgb(255, 255, 255)",
                            color: "gray",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                        }}
                    >
                        <FcGoogle
                            style={{
                                marginLeft: "5px",
                                fontSize: "30px",
                            }}
                        />
                        Entrar com Google
                    </button>
                )}
                scope={"https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets.readonly"}
            />
        </div>
    );
}