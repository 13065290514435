import React from 'react';

export const InputWithLimit = ({ type, value, setValue, limit, placeholder }) => {
  const handleChange = ({ target }) => {
    if (target.value.length <= limit) {
      setValue(target.value);
    }
  };

  if (type === 'select') {
    return (
      <select value={value} onChange={handleChange}>
        <option value="100">100</option>
        <option value="1000">1000</option>
        <option value="5000">5000</option>
        <option value="10000">10000</option>
        <option value="50000">50000</option>
      </select>
    );
  }

  return (
    <input
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={handleChange}
    />
  );
};
