import React, { useState, useRef, useEffect } from 'react';
import './InfoTable.css';
import Swal from 'sweetalert2';
import { TbMapSearch } from "react-icons/tb";
import { FaCopy } from "react-icons/fa";

export default function InfoTable({ codes, title, length }) {
  const [isCopied, setIsCopied] = useState(false);
  const codeTableRef = useRef(null);

  useEffect(() => {
    if (isCopied) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Códigos copiados para a área de transferência",
        timer: 1500
      });
    }
  }, [isCopied]);
  
  const handleCopyCodes = async () => {
    const codeTableElement = codeTableRef.current;
    const range = document.createRange();
    range.selectNodeContents(codeTableElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    setIsCopied(true);
    await new Promise((resolve) => setTimeout(resolve, 4000));
    setIsCopied(false);
  };

  return (
    <section className='container__infotable'>
      <div className='info-header'>
        <TbMapSearch  />
        <h2>{ title }</h2>
        <p>{length}</p>
        {codes && (
          <button className='btn-copy' type="button" onClick={handleCopyCodes}>
            <FaCopy />
          </button>
        )}
      </div>

      <ul className='code-info' ref={codeTableRef}>
        {codes === null ? null : codes.map((code) => (<li key={code + 1}>{code}</li>))}
      </ul>
      
    </section>
  );
}
