import Main from './components/Main';
import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Logout from './components/Logout';
import './App.css';
import User from './components/User';
import { gapi } from 'gapi-script';
import Manual from './components/Manual';
import { BsWhatsapp } from "react-icons/bs";
import DisableDevtool from 'disable-devtool';

DisableDevtool({
  url: 'https://www.google.com',
});

const clientId = process.env.CLIENT_ID;

function App() {
  const [emailList, setEmailList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    function start() {
      gapi.client.init({
        apiKey: "AIzaSyCFrLJRR_sK3mGXUSGmj2xJ5aglmUx15jI",
        clientId: clientId,
        oauth_scope: ["https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/spreadsheets.readonly"],
        discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"]
      }).then(() => {
        return gapi.client.load('sheets', 'v4');
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        setIsLoggedIn(authInstance.isSignedIn.get());
        authInstance.isSignedIn.listen(updateSigninStatus);
        if (authInstance.isSignedIn.get()) {
          getUserEmail();
          getEmailList();
        }
      }).catch((error) => {
        console.error('Erro ao iniciar o cliente:', error);
      });
    };

    function updateSigninStatus(isSignedIn) {
      setIsLoggedIn(isSignedIn);
      if (isSignedIn) {
        getUserEmail();
        getEmailList();
      }
    }

    function getUserEmail() {
      const user = gapi.auth2.getAuthInstance().currentUser.get();
      setUser({
        email: user.getBasicProfile().getEmail(),
        name: user.getBasicProfile().getName(),
        image: user.getBasicProfile().getImageUrl()
      });
    };

    async function getEmailList() {
      try {
        const response = await fetch(`https://content-sheets.googleapis.com/v4/spreadsheets/1vEwk567DTNyHfPW5CyJ7zRMAgLBgEou2NczaPGzIXSQ/values/A%3AA?key=AIzaSyCFrLJRR_sK3mGXUSGmj2xJ5aglmUx15jI`);
        const data = await response.json();
        const values = data.values;
        setEmailList(values ? values.flat() : []);
      } catch (error) {
        console.error('Erro ao obter a lista de e-mails:', error);
      }
    };

    gapi.load('client', start);
  }, []);

  const logout = () => {
      setIsLoggedIn(false);
      setUser("");
  };

  const fn_referCode = async (setReference) => {
    try {
      const response = await fetch(`https://content-sheets.googleapis.com/v4/spreadsheets/1vEwk567DTNyHfPW5CyJ7zRMAgLBgEou2NczaPGzIXSQ/values/E2:E2?key=AIzaSyCFrLJRR_sK3mGXUSGmj2xJ5aglmUx15jI`);
      const data = await response.json();
      const values = data.values;
      setReference(values ? values.flat() : "");
    } catch (error) {
      console.error('Erro ao obter o código de referência:', error);
    }
  };

  const fn_verifyUser = async () => {
    try {
      const response = await fetch(`https://content-sheets.googleapis.com/v4/spreadsheets/1vEwk567DTNyHfPW5CyJ7zRMAgLBgEou2NczaPGzIXSQ/values/A%3AA?key=AIzaSyCFrLJRR_sK3mGXUSGmj2xJ5aglmUx15jI`);
      const data = await response.json();
      const values = data.values;
      setEmailList(values ? values.flat() : []);
      return emailList.includes(user.email);
    } catch (error) {
      console.error('Erro ao obter a lista de e-mails:', error);
    }
  };

  return (
    <div className='container__app'>
      <div className='container__user'>
        {isLoggedIn ? (
          <User
            userEmail={user.email}
            userName={user.name}
            userImage={user.image}
            emailList={emailList}
          />
        ) : (
          <div className='user'>
            <h1>Faça login para verificar seu e-mail</h1>
            <p>Entre em contato conosco para adquirir o acesso!</p>
            <a 
              className='btn-share' 
              href="https://api.whatsapp.com/send?phone=22943753950&amp;text=Olá%2C+gostaria+de+ter+acesso+à+melhor+ferramenta+de+códigos+de+rastreio.+Como+faço%3F" 
              target="__blank">
              <BsWhatsapp/> WhatsApp
            </a>
          </div>
        )}
        <div className='container__login'>
          <Login />
          <Logout logout={logout}/>
        </div>
        <Manual />
      </div>
      <Main 
          fn_verifyUser={fn_verifyUser}
          fn_referCode={fn_referCode}
      />
    </div>
  );
}

export default App;