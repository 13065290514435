import React from 'react';
import "./User.css";
import { FaUserCircle } from "react-icons/fa";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { BiSolidError } from "react-icons/bi";

export default function User({ userEmail, userName, userImage, emailList }) {
    return (
        <div className='user'>
            <h1>Olá, {userName}</h1>
            <img src={userImage} alt="User" />
            <p><FaUserCircle /> {userEmail}</p>
            {emailList.includes(userEmail) ? (
                <p id='user-released'><BsFillPatchCheckFill />Acesso liberado!</p>
            ) : (
                <p id='user-no-access'><BiSolidError />Seu e-mail ainda não possui acesso a ferramenta</p>
            )}
        </div>
    );
};